<template>
  <div class="footer">
    <div class="footer_one">
      <div class="footer_one_l">
        <img class="l_o" :src="homeInfo.top_logo" alt="" />
        <!-- <div class="l_t">茶友茗泉</div>
        <div class="l_h">CHA YOU MING QUAN</div> -->
      </div>
      <div class="footer_one_m">
        <div class="footer_one_m_tit">网站导航</div>
        <div class="footer_one_m_cont">
          <div
            style="cursor: pointer"
            @click="handleClick(item.url)"
            v-for="(item, index) in navigateList"
            :key="index"
          >
            {{ item.name }}
          </div>
          <!-- <div style="cursor: pointer;" @click="handleClick(1)">品牌故事</div>
          <div style="cursor: pointer;" @click="handleClick(2)">产品介绍</div>
          <div style="cursor: pointer;" @click="handleClick(3)">新闻资讯</div>
          <div style="cursor: pointer;" @click="handleClick(4)">关于我们</div> -->
        </div>
        <div class="footer_one_m_tit">友情链接</div>
        <div class="footer_one_m_cont">
          <div
            v-for="(item, index) in linkList"
            style="cursor: pointer"
            :key="index"
            @click="tourl(item.url)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="footer_one_r">
        <!-- <div class="footer_one_r_item">
          <div>公众号</div>
          <img :src="homeInfo.qr_code" alt="" />
        </div> -->
        <div class="footer_one_r_item">
          <div>小程序</div>
          <img :src="homeInfo.wxapp_code" alt="" />
        </div>
      </div>
    </div>
    <div class="footer_two">
      Copyright {{ homeInfo.name }}
      <div style="width: 18px; display: inline-block" class="btns"></div>
      <span class="btns" @click="jsurl">技术支持： {{ homeInfo.cp_skill }}</span>
      <div style="width: 18px; display: inline-block"></div>
      <span class="btns" @click="ba">备案号：{{ homeInfo.beian }}</span>
      <div style="width: 18px; display: inline-block" class="btns"></div>
      <span>{{ homeInfo.beian_ga }}</span>
    </div>
  </div>
</template>

<script>
import { friendshipLink, globalConfig, navigate } from "../../../api/index";
export default {
  name: "Bottom",
  components: {},
  data() {
    return {
      linkList: [],
      naveTopList: [],
      homeInfo: {},
      navigateList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ba() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    jsurl() {
      window.open(this.homeInfo.cp_skill_url)
    },
    init() {
      friendshipLink().then((res) => {
        this.linkList = res.data;
      });

      globalConfig().then((res) => {
        this.homeInfo = res.data;
      });

      navigate().then((res) => {
        this.navigateList = res.data;
      });
    },
    tourl(url) {
      location.href = url;
    },
    handleClick(index) {
      this.$store.commit("swicthTab", String(Number(index)));
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push({
        path: "/Index/routerboy" + (Number(index) - 1),
      });

      this.$store.commit("swicthTab", String(index));
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  background: rgba(0, 0, 0, 0.5);
  min-width: 1300px;
  background-color: #f3f5f6;
  .footer_one {
    width: 1300px;

    margin: 0 auto;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footer_one_l {
      font-weight: 700;
      margin-right: 50px;
      text-align: center;
      .l_o {
        width: 210px;
        height: 100px;
      }
      .l_t {
        font-size: 32px;
      }
      .l_h {
        font-size: 16px;
      }
    }
    .footer_one_m {
      // flex: 1;
      margin: 0 25px;
      padding: 0 50px;
      border-left: 1px dashed;
      border-right: 1px dashed;
      .footer_one_m_tit {
        color: rgba(0, 0, 0, 0.8);
        font-size: 18px;
        margin-bottom: 15px;
      }
      .footer_one_m_cont {
        display: flex;
        flex-wrap: wrap;
        color: rgba(0, 0, 0, 0.4);
        > div {
          font-size: 16px;
          margin-bottom: 15px;
          margin-right: 50px;
          color: #303133;
          &:hover {
            color: #409eff;
          }
        }
      }
    }
    .footer_one_r {
      display: flex;
      .footer_one_r_item {
        color: rgba(0, 0, 0, 0.8);
        font-size: 18px;
        text-align: center;
        margin-right: 30px;
        > img {
          width: 130px;
          height: 130px;
          margin-top: 16px;
        }
      }
    }
  }
  .footer_two {
    background-color: #f3f5f6;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    span {
      cursor: pointer;
    }
    .btns {
      &:hover {
        color: #409eff;
      }
    }
  }
}
</style>