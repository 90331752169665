<template>
  <div class="header">
    <div class="header-tabs">
      <div class="header_one">
        <div class="header_one_left">
          <!-- 欢迎来到茶友茗泉官网，我们竭诚为您服务！您可以直接拨打电话联系我们。 -->
          <Scroller></Scroller>
        </div>
        <div class="header_one_right">
          <div class="header_one_right_item" v-for="(item,index) in naveTopList" :key="index" @click="toLinks(item.url)">
              <img class="imgBox" :src="item.logo" v-if="item.logo" />
            <div>{{item.name}}</div>
          </div>

        </div>
      </div>

      <div class="header_two">
        <div class="header_two_left" @click="toHomes">
          <img :src="homeInfo.top_logo" alt="" v-if="homeInfo.top_logo" />
          <!-- <div class="header_two_left_r">
            <div class="t">茶友茗泉</div>
            <div>CHA YOU MING QUAN</div>
          </div> -->
        </div>
        <div class="header_two_right">
          <el-tabs
            class="header_two_right_tabs"
            v-model="activeName"
            @tab-click="handleClick"
          >
            <el-tab-pane :label="item.name" :name="item.url" v-for="(item,index) in navigateList" :key="index"></el-tab-pane>
            <!-- <el-tab-pane label="品牌故事" name="2"></el-tab-pane>
            <el-tab-pane label="产品介绍" name="3"></el-tab-pane>
            <el-tab-pane label="新闻资讯" name="4"></el-tab-pane>
            <el-tab-pane label="关于我们" name="5"></el-tab-pane> -->
          </el-tabs>
          <div class="header_two_right_r">
            <img src="../../assets/imgs/3.png" alt="" />
            <div>{{homeInfo.cp_mobile}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="header_four">
      <el-carousel :interval="5000" :height="height" arrow="never" autoplay loop>
        <el-carousel-item v-for="(item,index) in carouselList" :key="index">
          <img
            class="item_img"
            :src="item"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { naveTop ,navigate , globalConfig} from "../../api/index";
import Scroller from './scrollerText'
export default {
  name: "index",
  components: {
    Scroller
  },
  data() {
    return {
      activeName: "1",
      naveTopList:[
        {name:""},{name:""}
      ],
      navigateList:[],
      homeInfo:{},
      indexs:1,
      carouselList:[],
      height:'',
    };
  },
  created() {
    
  },
  beforeMount:function(){
         this.height = document.body.clientWidth*516/1920 + 'px';
},
   watch: {
    "$store.state.tabBarIndex"(newval, olval) {
      console.log(newval);
      this.activeName = newval;
      this.indexs=localStorage.getItem('swicthTabIndex')||1;
      this.getNaveTop()
    },
},
mounted(){
  this.indexs=localStorage.getItem('swicthTabIndex')||1;
  this.getNaveTop()
  if(localStorage.getItem('swicthTabIndex')){
    this.activeName=localStorage.getItem('swicthTabIndex')

  }
  
},
  methods: {
    getNaveTop() {
      console.log('123456');
      naveTop().then((res) => {
        this.naveTopList = res.data
      });

      navigate().then((res)=>{
        this.navigateList = res.data;
        this.carouselList = res.data[this.indexs-1].images;
      })

      globalConfig().then((res) => {
        this.homeInfo = res.data;
      });
    },
    toHomes(){
      this.$store.commit("swicthTab", '1');
      document.body.scrollTop=document.documentElement.scrollTop=0
      this.$router.push({
        path: "/Index/routerboy0"
      });
    },
    handleClick(tab, event) {
      this.$store.commit("swicthTab", String(Number(tab.index)+1));
      document.body.scrollTop=document.documentElement.scrollTop=0
      this.$router.push({
        path: "/Index/routerboy" + tab.index,
      });
    },
    toDetail(urls){
      this.$store.commit("swicthTab", String(Number(urls)));
      document.body.scrollTop=document.documentElement.scrollTop=0

      this.$router.push({
        path:'/Index/routerboy'+ urls,
      });
    },
    toLinks(url){
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  // height: 260px;

  .header-tabs {
    position: fixed;
    width: 100%;
    background-color: #fff;
    top: 0;z-index: 100;
    .header_one {
      width: 1300px;
      margin: 0 auto;
      height: 80px;
      line-height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
      .header_one_left {
        width: 380px;
      }
      .header_one_right {
        display: flex;
        align-items: center;
        .header_one_right_item {
          display: flex;
          align-items: center;
          margin-right: 40px;
          .imgBox {
            width: 28px;
            height: 28px;
            text-align: center;
            color: #fff;
            line-height: 28px;
            // background-image: url("../../assets/imgs/1.png");
            background-size: 100% 100%;
            margin-right: 6px;
          }
          &:hover{
            color:#409EFF;
          }
        }
      }
    }
    .header_two {
      width: 1300px;
      margin: 0 auto 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header_two_left {
        display: flex;
        color: rgba(45, 116, 248, 100);
        margin: 0 auto;
        transform: translateX(20px);
        img {
          width: 124px;
          height: 62px;
          
        }
        .header_two_left_r {
          font-size: 12px;
          font-family: SourceHanSansSC-black;
          .t {
            font-size: 22px;
            font-weight: 700;
          }
        }
      }
      .header_two_right {
        display: flex;
        align-items: center;
        .header_two_right_tabs {
          /deep/.el-tabs__item {
            padding: 0 40px;
            font-size: 16px;
          }
          /deep/.el-tabs__item.is-active {
            font-size: 18px;
            font-weight: 700;
          }
          /deep/.el-tabs__nav-wrap::after {
            display: none;
          }
          /deep/.el-tabs__active-bar {
            height: 4px;
          }
          /deep/.el-tabs__header {
            margin: 0;
          }
        }
        .header_two_right_r {
          display: flex;
          align-items: center;
          &:hover{
            color: #409EFF;
            cursor: pointer;
          }
          > img {
            width: 32px;
            height: 32px;
            margin: 0 12px 0 160px;
          }
          font-size: 16px;
        }
      }
    }
  }

  .header_four {
    min-width: 1300px;
    margin-top: 142px;
    /deep/.el-carousel__container{
      height: auto;
    }
    /deep/.el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .el-carousel__item .item_img {
      // height: 100%;
      width: 100%;
    }
  }
}
</style>