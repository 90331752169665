import request from '../request/request'

export function naveTop() {
    return request({
        url: '/index/home_page/nave_top',
        method: 'get',
    })
}

export function getInfo() {
    return request({
        url: '/index/mall-app/admin/info',
        method: 'get'
    })
}

//最新资讯
export function information() {
    return request({
        url: '/index/home_page/information',
        method: 'get'
    })
}

//轮播
export function navigate() {
    return request({
        url: '/index/home_page/navigate',
        method: 'get'
    })
}

//合作案例 
export function casesList() {
    return request({
        url: '/index/home_page/cases_list',
        method: 'get'
    })
}

//友情链接 
export function friendshipLink() {
    return request({
        url: '/index/home_page/links',
        method: 'get'
    })
}

//品牌故事 
export function brandStory() {
    return request({
        url: '/index/brand/brand_story',
        method: 'get'
    })
}

//生态环境 
export function environment() {
    return request({
        url: '/index/brand/environment',
        method: 'get'
    })
}

//理念 
export function brandConcept() {
    return request({
        url: '/index/brand/brand_concept',
        method: 'get'
    })
}

//产品 多种规格 
export function productSpecs() {
    return request({
        url: '/index/product/product_specs',
        method: 'get'
    })
}


//产品 适用场景 
export function productScene(data) {
    return request({
        url: '/index/product/product_scene',
        method: 'get',
        params: data
    })
}

//关于我们 
export function aboutUs() {
    return request({
        url: '/index/about_us/about_us',
        method: 'get'
    })
}

//荣誉资质 
export function honorary() {
    return request({
        url: '/index/about_us/honorary',
        method: 'get'
    })
}

//分公司管理 
export function company() {
    return request({
        url: '/index/about_us/company',
        method: 'get'
    })
}

//资讯分类 
export function category() {
    return request({
        url: '/api/information/category',
        method: 'get'
    })
}

//资讯列表 
export function categoryList(data) {
    return request({
        url: '/api/information/lists',
        method: 'get',
        params: data
    })
}

//资讯详情 
export function categoryListDetail(data) {
    return request({
        url: '/api/information/details',
        method: 'get',
        params: data
    })
}

//配置信息 
export function globalConfig(data) {
    return request({
        url: '/index/home_page/global_config',
        method: 'get',
        params: data
    })
}

//产品介绍 
export function product_intro(data) {
    return request({
        url: '/index/product/product_intro',
        method: 'get',
        params: data
    })
}

//在线留言 
export function message(data) {
    return request({
        url: '/index/home_page/message',
        method: 'post',
        data: data
    })
}


//产品介绍详情 
export function product_intro_detail(data) {
    return request({
        url: '/index/product/product_intro_detail',
        method: 'get',
        params: data
    })
}